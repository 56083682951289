import { useLocation, useHistory } from 'react-router-dom';

import { getHashParams } from '@Core/helpers/url';

export const hotelHashParams = ['hotelTab', 'hotelUrl', 'modalView', 'hotelMasterId'] as const;

type HotelQueryParams<T extends readonly string[]> = {
  [Key in T[number]]?: string;
};

let shouldGoBack = false;

export const useHdpModalUrlUpdate = () => {
  const { search, state, pathname, hash } = useLocation();
  const hashParams = getHashParams(hash);

  const { push, goBack, replace } = useHistory();

  const addUrlHashParams = (params: HotelQueryParams<typeof hotelHashParams>) => {
    const newHashParams = Object.entries({
      ...hashParams,
      ...params,
    })
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

    shouldGoBack = true;
    push(`${pathname}${search}${newHashParams ? `#${newHashParams}` : ''}`, state);
  };

  const removeUrlHashParams = () => {
    const hashParamsToKeep = Object.entries({ ...hashParams })
      .filter((param) => {
        const [key] = param;

        return !hotelHashParams.includes(key as any);
      })
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

    if (shouldGoBack) {
      goBack();
    } else {
      replace(`${pathname}${search}${hashParamsToKeep ? `#${hashParamsToKeep}` : ''}`, state);
    }
  };

  return {
    addUrlHashParams,
    removeUrlHashParams,
  };
};
