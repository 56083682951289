import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { ModalEvent, ModalHook, useModal } from '@Components/Modal/useModal';
import { getHashParams } from '@Core/helpers/url';
import { useHdpModalUrlUpdate } from '@Pages/search-results/hotel-details-modal/useHdpModalUrlUpdate';

export const useModalWithHash = <E extends ModalEvent>(
  modalViewKey: string,
  ...args: Parameters<typeof useModal<E>>
): ModalHook<E> => {
  const { addUrlHashParams, removeUrlHashParams } = useHdpModalUrlUpdate();
  const [isOpen, open, close] = useModal<E>(...args);
  const { hash } = useLocation();
  const { modalView } = getHashParams(hash);

  const openWithHash = useCallback(
    (event?: E) => {
      open(event);
      addUrlHashParams({ modalView: modalViewKey });
    },
    [open, addUrlHashParams, modalViewKey],
  );

  const closeWithHash = useCallback(
    (event?: E) => {
      close(event);
      removeUrlHashParams();
    },
    [close, removeUrlHashParams],
  );

  useEffect(() => {
    if (modalView === modalViewKey && !isOpen) {
      open();
    } else if (modalView !== modalViewKey && isOpen) {
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalView]);

  return [isOpen, openWithHash, closeWithHash];
};

export default useModalWithHash;
